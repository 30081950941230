import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "memUsageContainer", "cpuUsageContainer", "diskUsageContainer", "cpuUsageLoader",
    "memUsageLoader", "diskUsageLoader", "diskStatusTab"]

  connect() {
    this.initChart()
    this.fetchChartDataForRange()
  }

  initChart() {
    let chartData = this.createChartData()
    this.cpu_usage_chart = this.buildChart(this.cpuUsageContainerTarget, chartData)
    this.mem_usage_chart = this.buildChart(this.memUsageContainerTarget, chartData)
    this.disk_usage_chart = this.buildChart(this.diskUsageContainerTarget, chartData)
  }

  buildChart(target, chartData) {
    return new Chart(target, {
      type: "bar",
      data: chartData,
      options: {
        "scales": {
          "yAxes": [{
            "ticks": {
              "beginAtZero": true
            }
          }]
        },
        elements: {
          point: {
            radius: 0 // 禁用所有点的显示
          }
        }
      },
      plugins: {},
    })
  }

  createChartData(payload = { tss: [], data: [] }, label="") {
    if (payload.chart_type && payload.chart_type == "bar") {
      return {
        "labels": payload.tss,
        "datasets": [{
          "label": this.data.get(label),
          "backgroundColor": "#6777ef",
          "borderColor": "#6777ef",
          "data": payload.data
        }]
      }
    } else {
      return {
        "labels": payload.tss,
        "datasets": [{
          "type": "line",
          "label": this.data.get(label),
          "backgroundColor": "#6777ef",
          "borderColor": "#6777ef",
          "data": payload.data
        }]
      }
    }
  }

  fetchChartDataForRange() {

    $(this.cpuUsageContainerTarget).hide()
    $(this.cpuUsageLoaderTarget).show()
    $(this.memUsageContainerTarget).hide()
    $(this.memUsageLoaderTarget).show()
    $(this.diskUsageContainerTarget).hide()
    $(this.diskUsageLoaderTarget).show()
    const dataUrl = this.data.get("dataUrl")
    const that = this
    $.ajax({
      method: "GET",
      url: dataUrl
    })
    .done(function(payload) {
      that.showChart(payload)
      that.setDiskStatusTable(payload)
    })
  }

  showChart(payload) {
    $(this.cpuUsageLoaderTarget).hide()
    let cpuUsageData = {
      "tss": payload.tss,
      "data": payload.cpu_usage
    }
    let cpuUsageChartData = this.createChartData(cpuUsageData, "cpuUsageLabel")
    this.cpu_usage_chart.data = cpuUsageChartData
    this.cpu_usage_chart.update()
    $(this.cpuUsageContainerTarget).show()

    $(this.diskUsageLoaderTarget).hide()
    let diskUsageData = {
      "tss": payload.tss,
      "data": payload.disk_usage
    }
    let diskUsageChartData = this.createChartData(diskUsageData, "diskUsageLabel")
    this.disk_usage_chart.data = diskUsageChartData
    this.disk_usage_chart.update()
    $(this.diskUsageContainerTarget).show()

    $(this.memUsageLoaderTarget).hide()
    let memUsageData = {
      "tss": payload.tss,
      "data": payload.memory_usage
    }
    let memUsageChartData = this.createChartData(memUsageData, "cpuUsageLabel")
    this.mem_usage_chart.data = memUsageChartData
    this.mem_usage_chart.update()
    $(this.memUsageContainerTarget).show()
  }

  setDiskStatusTable(payload) {
    let disk_status = payload.disk_status
    let tbody = ""
    disk_status.forEach((d) => {
      tbody += `<tr><td>${d['disk_name']}</td><td>${d['disk_size']}</td><td>${d['disk_used_size']}</td><td>${d['disk_usage']}</td></tr>`
    })
    this.diskStatusTabTarget.innerHTML = tbody
  }
}
